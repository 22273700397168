<template>
  <div>
    <v-card class="mt-2" 
    style="height: 86vh;">
      <iframe
        src="./manual/index.html"
        width="100%"
        style="position: absolute; height: 100%;  border: none"
      ></iframe>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ManualViewer",
  components: {},
  data: () => {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style>
</style>