<template>
  <div>
    <v-card>
      <v-card-title>
        Manual do Sistema
        <v-spacer> </v-spacer>
        <v-btn text color="success" @click="showForm = !showForm"
          >Adicionar Manual</v-btn
        >
      </v-card-title>
      <!-- FORM -->
      <v-card-text v-show="showForm">
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  label="Nome do Manual"
                  v-model="manual.name"
                  :counter="255"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="manual.description"
                  rows="1"
                  label="Descrição"
                  hint="Descrição do Manual"
                  :counter="255"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="manual.html"
                  rows="3"
                  label="Codigo HTML"
                  hint="Codigo HTML"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div v-html="manual.html"></div>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDialoag">
                Cancelar
              </v-btn>
              <v-btn color="success" text @click="save"> Salvar </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <!-- TABELA -->
    <v-card class="mt-2">
      <v-simple-table dark>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">ID</th>
              <th class="text-left">Nome</th>
              <th class="text-left">Descrição</th>
              <!-- <th class="text-left">HTML</th> -->
              <th class="text-left">Cadastrado</th>
              <th class="text-left">Editado</th>
              <th class="text-left">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in $store.getters.getManuais" :key="index">
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <!-- <td v-html="item.html"></td> -->
              <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
              <td>{{ new Date(item.updatedAt).toLocaleString() }}</td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="edit(item)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="del(item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Excluir</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="show(item)">
                      <v-icon>mdi-play</v-icon>
                    </v-btn>
                  </template>
                  <span>Ver o Manual</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Manual",
  components: {},
  data: () => {
    return {
      search: "",
      ip: "",
      tomcatPort: 8080,
      showForm: false,
      dialog: false,
      manual: {
        name: "",
        groupId: 0,
        hmtl: "",
        description: "",
      },
    };
  },
  methods: {
    get() {
      this.$store.dispatch("loadManuais");
    },
    closeDialoag() {
      // this.cliente = {};
      // this.dialog = false;
    },
    openDialoag() {
    },
    save() {
      this.$store.dispatch("saveManual", this.manual);
      // this.closeDialoag();
      this.showForm = false;
    },
    // del(item) {
      // console.log(item);
      // this.$store.dispatch("deletCliente", item);
    // },
    edit(item) {
      this.showForm = true;
      this.manual = item;
    },
    show(item){
      // console.log(item);
      this.$router.push({
        path: "/manualView",
        query: {
          manual: item.id,
        },
      });
    }
  },
  computed: {
  },
  mounted() {
    this.get();
  },
};
</script>

<style>
</style>